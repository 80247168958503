.center {
    justify-content: center;
    // align-items: center;
}

.border_box {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 0px 6px 0px;
    border-radius: 10px;
    padding: 4px 16px;
    width: 100%;
    color: #000;
    ul {
        margin: 0;
        padding-left: 16px;
    }
}

.text_color_front{
    color: rgb(255, 209, 92);
}

.text_color_back{
    color:rgb(255, 76, 96);
}
.text_color_back_b {
    color: rgb(108, 108, 229);
}
.text_color_other_y{
    color:rgb(255, 209, 92);
}
.text_color_other_r {
    color: rgb(255, 76, 96);
}

.text_color_other_b {
    color: rgb(108, 108, 229);
}
